.application-form{
    display: flex;
    flex-direction: column;
    background-color: #252525;
    padding: 20px;
    border-radius: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 40px
}

.spinner{
    align-self: flex-end;
    margin: 0 50px
}

.application-form .form-layout{
    display: grid;
    grid-template-columns: auto 400px;
    grid-gap: 20px;
}

.application-form .form-layout .fields{
    display: flex;
    flex-direction: column;
}

.application-form .form-layout .fields :is(input, textarea){
    margin: 10px 0;
}

.application-form button{
    align-self: flex-end;
}

.fields .help-text{
    color: #fed142;
    margin: 10px;
    margin-left: 30px;
}

.payment-options-container{
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    margin: 0 auto
}

.group-dates-container{
    background-color: #080808;
    padding: 20px;
    border-radius: 20px;
}

.group-dates-container .module{
    margin: 10px 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.group-dates-container .module:not(:last-child){
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.group-dates-container .module .date{
    text-align: right;
}

.application-form a{
    text-decoration: underline;
}

.checkbox-container{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 20px;
}

.checkbox{
    width: 35px;
    height: 35px;
    border: 1px solid white;
    border-radius: 10px;
    margin-right: 10px;
    position: relative;
}

.checkbox i{
    position: absolute;
    left: -8px;
    color: #fed142;
}

.company-fields{
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 10px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    flex-direction: column;
    margin: 20px 0;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .application-form{
        padding: 20px 5px;
    }

    .application-form input{
        margin-bottom: 10px;
    }

    .application-form .form-layout{
        display: flex;
        flex-direction: column;
    }

    .application-form .title{
        font-size: 40px;
        text-align: center;
    }

    .application-form h1{
        font-size: 30px;
        text-align: center;
    }

    .application-form h2{
        font-size: 26px;
    }

    .checkbox-container h2{
        font-size: 20px;
    }

    .checkbox{
        width: 28px;
        height: 28px;
        min-width: 28px;
        border-radius: 8px;
        margin-right: 16px;
    }

    .checkbox i{
        top: -5px
    }

    .group-dates-container{
        padding: 5px;
    }

    .group-dates-container .module{
        padding: 5px;
    }
}