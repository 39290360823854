@import "navbar.css";
@import "footer.css";
@import "home.css";
@import "gyik.css";
@import "contact.css";
@import "courses.css";
@import "course_details.css";
@import "application.css";


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: #080808;
    color: white;
    font-family: 'Quicksand', sans-serif;
    overflow-x: hidden;
}


.App{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
}

.error{
    color: red;
    font-weight: 700;
}

.error.right{
    text-align: right;
}

.center-text{
    text-align: center;
}

.error i{
    font-size: 18px;
}

.field-error{
    border-color: red;
    background-color: rgba(255, 0, 0, 0.199);
}

li{
    margin-left: 40px;
}

h1, h2, h3{
    font-weight: 100;
}

i{
    font-size: 30px;
    padding-left: 10px
}

a{
    text-decoration: none;
    color: inherit;
}

a:hover{
    color: #fed142;
    transition: color 0.3s;
}

/* scrollbar setup */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #3571a3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #63a4da;
}

.content-container {
    padding-top: 100px;
}

.center-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

hr{
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 20px 50px
}

input, textarea, button{
    font: inherit;
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: inherit;
}

textarea {
    resize: vertical;
}

button {
    cursor: pointer;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 40px
}

button:hover{
    background-color: #fed142;
    color: black;
    transition: all 0.3s;
}

.title{
    font-size: 70px;
    font-weight: 700;
    color: #fed142;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .content-container{
        padding-top: 56px;
    }
}

