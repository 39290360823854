.home{
    padding-top: 30px;
}

.home .card{
    min-height: 600px;
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.divider{
    height: 100%;
    width: 5px;
    background-color: white;
    justify-self: center;
}

.card.center{
    display: flex;
    justify-content: center;
}

.home .card h1{
    font-weight: 700;
    font-size: 100px;
    text-align: right;
    align-self: center;
    color: #fed142;
}

.home .card p{
    font-size: 50px;
    align-self: center;
}

.card.center :is(p, h1){
    border: none;
    text-align: center;
}

.home-footer{
    text-align: center;
    font-size: 60px;
    background-color: transparent;
    color: white;
    font-weight: 300;
    padding: 50px 0;
}

.home-footer a {
    margin-right: 20px;
    color: #fed142;
    font-weight: 700;
    text-decoration: underline;
}

.home-footer a:hover{
    color: white;
}

.about-me{
    max-width: 1000px;
    background-color: #252525;
    border-radius: 20px;
    padding: 20px;
    margin: 40px auto;
    display: flex;
    align-items: center;
}

.about-me img{
    height: 400px;
    border-radius: 20px;
}

.about-me .profile{
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .home{
        padding-top: 63px;
    }
    .card{
        min-height: 333px !important;
        display: flex !important;
        flex-direction: column;
    }

    .home .card h1{
        font-size: 50px;
        text-align: center;
    }

    .home .card p{
        font-size: 26px;
        text-align: center;
    }

    .divider{
        display: none !important;
    }

    .about-me{
        flex-direction: column;
        padding: 10px;
    }

    .home-footer{
        font-size: 30px;
        padding: 0;
        padding-bottom: 30px;
    }

    .about-me .profile{
        margin: 0;
    }

    .home-footer a{
        margin-right: 0;
    }

    .home .details{
        margin-top: 10px;
        font-size: 20px;
        text-align: center;
    }
}