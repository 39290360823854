.navbar{
    background-color: #252525;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.logo{
    width: 200px;
    position: absolute;
    bottom: -45px;
    z-index: 100;
}

.navbar .menu{
    display: inherit;
    margin-left: 200px;
}

.navbar .menu .menu-item{
    font-size: 26px;
    font-weight: 700;
    padding-left: 3px;
    margin-right: 20px;
    cursor: pointer;
}

.navbar .menu .menu-item:hover{
    color: #fed142;
    transition: color 0.3s;
}

.menu-item.active-page{
    color: #fed142;
}

.mobile-menu, .mobile-menu-button{
    display: none;
}

.close-box{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 152px;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .navbar .menu{
        display: none;
    }

    .logo{
        width: 142px;
        bottom: -40px;
    }

    .mobile-menu-button{
        display: block;
    }

    .mobile-menu-spacer{
        width: 60px;
    }

    .mobile-menu{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 40px;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 10px;
        background-color: black;
        width: 100%;
        font-size: 30px;
    }

    .navbar {
        padding: 5px;
    }
}