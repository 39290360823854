.course-header {
    background-color: #252525;
    padding: 30px;
    padding-top: 100px;
    display: grid;
    grid-template-columns: 600px auto;
    grid-gap: 20px;
}

.no-groups-container{
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.no-groups-container a {
    text-decoration: underline;
}

.course-header a{
    text-decoration: underline;
}

.course-header h1{
    text-align: left;
    margin-bottom: 10px;
}

.course-header ul{
    margin-top: 20px;
    list-style: none;
}

.course-header li {
    margin: 0;
}

.modules-container{
    width: 100%;
    height: 100%;
    position: sticky;
    top: 120px;
}

.course-header img{
    width: 100%;
    border-radius: 20px;
}

.course-details{
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    position: relative
}

.group-card{
    background-color: #252525;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 20px;
    min-width: 600px;
    border: 2px solid transparent
}

.group-card:hover{
    border: 2px solid #fed142;
    transition: all 0.3s
}

.group-card button{
    font-size: 18px;
    padding: 5px 20px
}

.group-card .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.module-container{
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
}

.module-dates{
    text-align: right;
}

.course-footer{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin: 30px auto
}

.course-footer a {
    text-decoration: underline;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .course-header{
        display: flex;
        flex-direction: column;
        padding: 5px;
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .course-header h1{
        text-align: center;
        font-size: 40px;
    }

    .modules-container h2{
        font-size: 20px;
    }

    .course-details{
        flex-direction: column;
        padding: 5px;
    }

    .group-card{
        min-width: 0;
    }

    .modules-container{
        position: static;
        margin-bottom: 50px;
    }

    .course-footer{
        font-size: 30px;
        margin: 0;
        margin-bottom: 30px;
    }

    .no-groups-container .title{
        font-size: 40px;
        text-align: center;
    }

    .no-groups-container h1{
        font-size: 25px;
        text-align: center;
    }
}