.course-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.course-card{
    display: flex;
    flex-direction: column;
    background-color: #252525;
    border-radius: 20px;
    padding: 30px;
    cursor: pointer;
    border: 2px solid transparent
}

.course-card:hover{
    border: 2px solid #fed142;
    transition: all 0.5s;
}

.course-card img{
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 20px;
}

.course-card .title{
    margin-bottom: 30px;
    text-align: center;
}

.course-card-content, .course-card-footer{
    text-align: center;
}

.course-card-footer{
    font-weight: 700;
    font-weight: 700;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .course-list{
        display: flex;
        flex-direction: column;
    }

    .course-card-footer{
        font-size: 20px;
    }

    .course-card{
        padding: 5px;
    }

    .course-card:not(:last-child){
        margin-bottom: 20px;
    }

    .course-card .title{
        font-size: 50px;
    }
}