.cotact-container{
    background-color: #252525;
    border-radius: 20px;
    padding: 30px;
    display: grid;
    grid-template-columns: 400px auto;
    grid-gap: 50px;
    max-width: 1200px;
    margin: 20px auto
}

.cotact-container img{
    width: 100%;
}

.cotact-container form{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.cotact-container input, textarea{
    margin-bottom: 20px;
}

.cotact-container button{
    align-self: flex-end;
}

.contact-form-container .text{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message-sent{
    font-size: 40px;
    font-weight: 700;
    color: #fed142;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .cotact-container{
        display: flex;
        flex-direction: column;
        padding: 20px 5px;
        padding-bottom: 0px;
        width: auto;
        align-items: center;
        margin-bottom: 20px;
    }

    .cotact-container img{
        width: 250px;
    }

    .contact-form-container .title{
        font-size: 50px;
        text-align: center;
    }

    .contact-form-container .text{
        text-align: center;
    }

    .contact-form-container button{
        align-self: center;
    }

    .message-sent{
        margin-bottom: 30px;
    }
}