.navbar{
    background-color: #252525;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: fixed;
    width: 100%;
    z-index: 100;
}

.logo{
    width: 200px;
    position: absolute;
    bottom: -45px;
    z-index: 100;
}

.navbar .menu{
    display: inherit;
    margin-left: 200px;
}

.navbar .menu .menu-item{
    font-size: 26px;
    font-weight: 700;
    padding-left: 3px;
    margin-right: 20px;
    cursor: pointer;
}

.navbar .menu .menu-item:hover{
    color: #fed142;
    transition: color 0.3s;
}

.menu-item.active-page{
    color: #fed142;
}

.mobile-menu, .mobile-menu-button{
    display: none;
}

.close-box{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 152px;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .navbar .menu{
        display: none;
    }

    .logo{
        width: 142px;
        bottom: -40px;
    }

    .mobile-menu-button{
        display: block;
    }

    .mobile-menu-spacer{
        width: 60px;
    }

    .mobile-menu{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 40px;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 10px;
        background-color: black;
        width: 100%;
        font-size: 30px;
    }

    .navbar {
        padding: 5px;
    }
}
.footer{
    background-color: #252525;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #777777;
    z-index: 10;
    font-size: 18px;
}

.footer div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .footer{
        flex-direction: column;
    }

    .footer div{
        margin: 5px 0;
        font-size: 22px;
    }
}
.home{
    padding-top: 30px;
}

.home .card{
    min-height: 600px;
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.divider{
    height: 100%;
    width: 5px;
    background-color: white;
    justify-self: center;
}

.card.center{
    display: flex;
    justify-content: center;
}

.home .card h1{
    font-weight: 700;
    font-size: 100px;
    text-align: right;
    align-self: center;
    color: #fed142;
}

.home .card p{
    font-size: 50px;
    align-self: center;
}

.card.center :is(p, h1){
    border: none;
    text-align: center;
}

.home-footer{
    text-align: center;
    font-size: 60px;
    background-color: transparent;
    color: white;
    font-weight: 300;
    padding: 50px 0;
}

.home-footer a {
    margin-right: 20px;
    color: #fed142;
    font-weight: 700;
    text-decoration: underline;
}

.home-footer a:hover{
    color: white;
}

.about-me{
    max-width: 1000px;
    background-color: #252525;
    border-radius: 20px;
    padding: 20px;
    margin: 40px auto;
    display: flex;
    align-items: center;
}

.about-me img{
    height: 400px;
    border-radius: 20px;
}

.about-me .profile{
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .home{
        padding-top: 63px;
    }
    .card{
        min-height: 333px !important;
        display: flex !important;
        flex-direction: column;
    }

    .home .card h1{
        font-size: 50px;
        text-align: center;
    }

    .home .card p{
        font-size: 26px;
        text-align: center;
    }

    .divider{
        display: none !important;
    }

    .about-me{
        flex-direction: column;
        padding: 10px;
    }

    .home-footer{
        font-size: 30px;
        padding: 0;
        padding-bottom: 30px;
    }

    .about-me .profile{
        margin: 0;
    }

    .home-footer a{
        margin-right: 0;
    }

    .home .details{
        margin-top: 10px;
        font-size: 20px;
        text-align: center;
    }
}
.gyik-container{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 500px auto;
    max-width: 1200px;
    margin: 0 auto
}

.gyik-container img{
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 70px
}

.gyik-card{
    margin-bottom: 20px;
    background-color: #252525;
    padding: 50px;
    border-radius: 20px;
}

.gyik-card .title{
    font-size: 40px;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .gyik-container{
        display: flex;
        flex-direction: column;
    }

    .gyik-card{
        padding: 20px 5px;
    }

    .gyik-card .title{
        font-size: 30px;
        text-align: center;
    }

    .gyik-card h2{
        font-size: 20px;
    }

    .gyik-container img{
        position: static;
    }
}
.cotact-container{
    background-color: #252525;
    border-radius: 20px;
    padding: 30px;
    display: grid;
    grid-template-columns: 400px auto;
    grid-gap: 50px;
    max-width: 1200px;
    margin: 20px auto
}

.cotact-container img{
    width: 100%;
}

.cotact-container form{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.cotact-container input, textarea{
    margin-bottom: 20px;
}

.cotact-container button{
    align-self: flex-end;
}

.contact-form-container .text{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message-sent{
    font-size: 40px;
    font-weight: 700;
    color: #fed142;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .cotact-container{
        display: flex;
        flex-direction: column;
        padding: 20px 5px;
        padding-bottom: 0px;
        width: auto;
        align-items: center;
        margin-bottom: 20px;
    }

    .cotact-container img{
        width: 250px;
    }

    .contact-form-container .title{
        font-size: 50px;
        text-align: center;
    }

    .contact-form-container .text{
        text-align: center;
    }

    .contact-form-container button{
        align-self: center;
    }

    .message-sent{
        margin-bottom: 30px;
    }
}
.course-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.course-card{
    display: flex;
    flex-direction: column;
    background-color: #252525;
    border-radius: 20px;
    padding: 30px;
    cursor: pointer;
    border: 2px solid transparent
}

.course-card:hover{
    border: 2px solid #fed142;
    transition: all 0.5s;
}

.course-card img{
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 20px;
}

.course-card .title{
    margin-bottom: 30px;
    text-align: center;
}

.course-card-content, .course-card-footer{
    text-align: center;
}

.course-card-footer{
    font-weight: 700;
    font-weight: 700;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .course-list{
        display: flex;
        flex-direction: column;
    }

    .course-card-footer{
        font-size: 20px;
    }

    .course-card{
        padding: 5px;
    }

    .course-card:not(:last-child){
        margin-bottom: 20px;
    }

    .course-card .title{
        font-size: 50px;
    }
}
.course-header {
    background-color: #252525;
    padding: 30px;
    padding-top: 100px;
    display: grid;
    grid-template-columns: 600px auto;
    grid-gap: 20px;
}

.no-groups-container{
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.no-groups-container a {
    text-decoration: underline;
}

.course-header a{
    text-decoration: underline;
}

.course-header h1{
    text-align: left;
    margin-bottom: 10px;
}

.course-header ul{
    margin-top: 20px;
    list-style: none;
}

.course-header li {
    margin: 0;
}

.modules-container{
    width: 100%;
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
}

.course-header img{
    width: 100%;
    border-radius: 20px;
}

.course-details{
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    position: relative
}

.group-card{
    background-color: #252525;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 20px;
    min-width: 600px;
    border: 2px solid transparent
}

.group-card:hover{
    border: 2px solid #fed142;
    transition: all 0.3s
}

.group-card button{
    font-size: 18px;
    padding: 5px 20px
}

.group-card .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.module-container{
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
}

.module-dates{
    text-align: right;
}

.course-footer{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin: 30px auto
}

.course-footer a {
    text-decoration: underline;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .course-header{
        display: flex;
        flex-direction: column;
        padding: 5px;
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .course-header h1{
        text-align: center;
        font-size: 40px;
    }

    .modules-container h2{
        font-size: 20px;
    }

    .course-details{
        flex-direction: column;
        padding: 5px;
    }

    .group-card{
        min-width: 0;
    }

    .modules-container{
        position: static;
        margin-bottom: 50px;
    }

    .course-footer{
        font-size: 30px;
        margin: 0;
        margin-bottom: 30px;
    }

    .no-groups-container .title{
        font-size: 40px;
        text-align: center;
    }

    .no-groups-container h1{
        font-size: 25px;
        text-align: center;
    }
}
.application-form{
    display: flex;
    flex-direction: column;
    background-color: #252525;
    padding: 20px;
    border-radius: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 40px
}

.spinner{
    align-self: flex-end;
    margin: 0 50px
}

.application-form .form-layout{
    display: grid;
    grid-template-columns: auto 400px;
    grid-gap: 20px;
}

.application-form .form-layout .fields{
    display: flex;
    flex-direction: column;
}

.application-form .form-layout .fields :is(input, textarea){
    margin: 10px 0;
}

.application-form button{
    align-self: flex-end;
}

.fields .help-text{
    color: #fed142;
    margin: 10px;
    margin-left: 30px;
}

.payment-options-container{
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    margin: 0 auto
}

.group-dates-container{
    background-color: #080808;
    padding: 20px;
    border-radius: 20px;
}

.group-dates-container .module{
    margin: 10px 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.group-dates-container .module:not(:last-child){
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.group-dates-container .module .date{
    text-align: right;
}

.application-form a{
    text-decoration: underline;
}

.checkbox-container{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 20px;
}

.checkbox{
    width: 35px;
    height: 35px;
    border: 1px solid white;
    border-radius: 10px;
    margin-right: 10px;
    position: relative;
}

.checkbox i{
    position: absolute;
    left: -8px;
    color: #fed142;
}

.company-fields{
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 10px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    flex-direction: column;
    margin: 20px 0;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .application-form{
        padding: 20px 5px;
    }

    .application-form input{
        margin-bottom: 10px;
    }

    .application-form .form-layout{
        display: flex;
        flex-direction: column;
    }

    .application-form .title{
        font-size: 40px;
        text-align: center;
    }

    .application-form h1{
        font-size: 30px;
        text-align: center;
    }

    .application-form h2{
        font-size: 26px;
    }

    .checkbox-container h2{
        font-size: 20px;
    }

    .checkbox{
        width: 28px;
        height: 28px;
        min-width: 28px;
        border-radius: 8px;
        margin-right: 16px;
    }

    .checkbox i{
        top: -5px
    }

    .group-dates-container{
        padding: 5px;
    }

    .group-dates-container .module{
        padding: 5px;
    }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: #080808;
    color: white;
    font-family: 'Quicksand', sans-serif;
    overflow-x: hidden;
}


.App{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
}

.error{
    color: red;
    font-weight: 700;
}

.error.right{
    text-align: right;
}

.center-text{
    text-align: center;
}

.error i{
    font-size: 18px;
}

.field-error{
    border-color: red;
    background-color: rgba(255, 0, 0, 0.199);
}

li{
    margin-left: 40px;
}

h1, h2, h3{
    font-weight: 100;
}

i{
    font-size: 30px;
    padding-left: 10px
}

a{
    text-decoration: none;
    color: inherit;
}

a:hover{
    color: #fed142;
    transition: color 0.3s;
}

/* scrollbar setup */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #3571a3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #63a4da;
}

.content-container {
    padding-top: 100px;
}

.center-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

hr{
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 20px 50px
}

input, textarea, button{
    font: inherit;
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: inherit;
}

textarea {
    resize: vertical;
}

button {
    cursor: pointer;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 40px
}

button:hover{
    background-color: #fed142;
    color: black;
    transition: all 0.3s;
}

.title{
    font-size: 70px;
    font-weight: 700;
    color: #fed142;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .content-container{
        padding-top: 56px;
    }
}


