.footer{
    background-color: #252525;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #777777;
    z-index: 10;
    font-size: 18px;
}

.footer div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .footer{
        flex-direction: column;
    }

    .footer div{
        margin: 5px 0;
        font-size: 22px;
    }
}