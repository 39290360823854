.gyik-container{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 500px auto;
    max-width: 1200px;
    margin: 0 auto
}

.gyik-container img{
    width: 100%;
    position: sticky;
    top: 70px
}

.gyik-card{
    margin-bottom: 20px;
    background-color: #252525;
    padding: 50px;
    border-radius: 20px;
}

.gyik-card .title{
    font-size: 40px;
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
    .gyik-container{
        display: flex;
        flex-direction: column;
    }

    .gyik-card{
        padding: 20px 5px;
    }

    .gyik-card .title{
        font-size: 30px;
        text-align: center;
    }

    .gyik-card h2{
        font-size: 20px;
    }

    .gyik-container img{
        position: static;
    }
}